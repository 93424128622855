import React,{useState} from 'react'
import '../styles/navbar.css'

//import menu from '../img/menu.png'
//import xmenu from '../img/xmenu.png'

  

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="Navbar">
      <h1 className="nav-logo">Quda Cars</h1>
      <div className={`nav-items ${isOpen && "open"}`}>
        <a href="/home">Home</a>
        <a href="/cardetails">Carlist</a>
        <a href="/about">About</a>
        <a href="/contact">Contact</a>
        <a href="/">Logout</a>
      </div>
      <div
        className={`nav-toggle ${isOpen && "open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="bar"></div>
      </div>
    </div>
  );
};

export default Navbar