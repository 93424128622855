import React from 'react'
import '../styles/Login.css'
import ques from '../img/q.png'
import {Link} from 'react-router-dom'
import Footer from '../components/Footer'
import Navlr from '../components/Navlr'



const Login = () => {
  return (
    <div className='login-container'>
      <Navlr/>
      <div className='login-body'>
        <div className='login-inner'>
        <form>
          <h1>Login</h1>
          <label>Email</label>

          <input type="email"/>

          <label>Password</label>
          
          <input type="password"/>

          <button className='btn-1'><Link to='/home'>Login</Link></button>
          <Link style={{padding:"10px",fontSize:"1.5rem"}}to='/reset'>Forgot Password?</Link>  
        </form>
        </div>
        <div className='vline'></div>
        <div className='ques-img'>
          <img src={ques} alt="Logo"/>
          <h2>New User?</h2>
          <button className='btn-1'><Link to='/register' >Register Here</Link></button>
        </div>
    </div>
      <h3 className='foot' style={{textAlign:"center"}}><Footer /></h3>
    </div>
  )
}

export default Login