import React,{useState} from 'react'
import '../styles/bookinfo.css'


const Bookinfo = (props) => {
  const[price,setPrice] = useState('1');
  const handlechange = (e) =>{
    setPrice(e.target.value)
  }
  return (
    <div className='bookinfo-body'>
        <div className='form-book'>
            <form>
                <label>Start Location</label>
                <input type="text" placeholder='Start Location'/>
                <label>Destination</label>
                <input type="text" placeholder='Destination'/>
                <label>Date</label>
                <input type="date" placeholder='Date' />
                <label>Time</label>
                <input type="time" placeholder='Date' /><br/>
                <label>No of Days</label>
                <input type="number"  name="days" onChange={handlechange} value={price} placeholder='days' min="1" max="10" /><br/>
                <label>Price : </label>
                <label>{props.price * price}</label><br/>
                <button className='btn-1'> Book Now</button>
                <p></p><br/>
            </form>
        </div>
    </div>
  )
}

export default Bookinfo