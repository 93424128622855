import React from 'react'
import '../styles/navlr.css'



const Navlr = () => {
  return (
    <div className='nav-login'>
        <nav>
            <h1>Quda Cars</h1>
        </nav>
    </div>
  )
}

export default Navlr