import React from 'react'
import '../styles/Register.css'
import {Link} from 'react-router-dom'
import Footer from '../components/Footer'
import Navlr from '../components/Navlr'




const Register = () => {
  return (
   <div className="reg-container">
    <Navlr/>
    <div className='reg-body'>
        <div className='reg-inner'>
        <form>
          <h1>Register</h1>
          <label>Name</label>
          <input type="text"/>
          <label>Email</label>         
          <input type="email"/>
          <label>Contact</label>         
          <input type="text"/>
          <label>Password</label>         
          <input type="password"/>
          <button className='btn-1' style={{width:"100px"}}><Link to='/' >Register</Link></button>
        </form>
        </div>
        <div className='vline'></div>
        <div className='regi-inner'>
        <form >
            <h1>Existing User</h1>
          <h1>Login</h1>
          <label>Email</label>

          <input type="email"/>

          <label>Password</label>
          
          <input type="password"/>

          <button className='btn-1' style={{width:"100px"}}><Link to='/home'>Login</Link></button>
          <Link className='link-for' style={{padding:"10px",fontSize:"1.5rem"}}to='/reset'>Forgot Password?</Link>  
        </form>
        </div>
    </div>
    <h3 className='foot' style={{textAlign:"center"}}><Footer /></h3>
   </div>
  )
}

export default Register