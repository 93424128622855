import React from 'react'
import { useParams } from 'react-router'
import carData from '../data/cardata';
import '../styles/bookingdetails.css'
import Bookinfo from '../components/Bookinfo';
import Navbar from '../components/Navbar'
//import Paymentinfo from '../components/Paymentinfo';

const Bookingdetails = () => {
  const {name}= useParams();
  const singleCarItem = carData.find((item) => item.carName === name);
  return (
    <div className='book-body'>
      <Navbar/>
        <h1>Reserve Your car Now</h1>
        <div className='booking-body'>
          	<div className='booking-left'>
                <table>
                  <tbody>
                    <tr><td>Brand</td><td>: {singleCarItem.brand}</td></tr>
                    <tr><td>Carname</td><td>: {singleCarItem.carName}</td></tr>
                    <tr><td>Price : Rs-</td><td>: {singleCarItem.price}</td></tr>
                    <tr><td>Availability</td><td>: {singleCarItem.avil}</td></tr>
                    <tr><td>Mileage</td><td>: {singleCarItem.speed}</td></tr>
                  </tbody>
                </table>
          	</div>

          <div className='booking-right'>
            	<img src={singleCarItem.imgUrl} alt='car'></img>
          </div>
        </div><h1>Book Your {singleCarItem.carName} Now </h1>
        <div className='bookinfo-comp'>
          	
          	<div className='bookinfo'>
            	<Bookinfo price={singleCarItem.price}/>
          	</div>
            <div className='bookinfo'>

          	</div>
        </div>
    </div>
  )
}
export default Bookingdetails