import React from 'react'
import Adnavbar from '../components/Adnavbar'
import carData from '../../data/cardata'
import '../styles/adminhome.css'




const Adhome = () => {
  const l = carData.length;
  var al=0, non=0,r=0;
  carData.map(values =>
    {
      const {avil}=values;
      if (avil=== "Available") {
        return al=al+1;
      }
      else if(avil=== "Non-Available"){
        return non=non+1;
      }
      else if(avil=== "Reserved"){
        return r=r+1;
      }
      return null;
    })
  return (
    <div className='adhome-body'>
        <div className='adhome-container'>
            <div className='adhome-nav'>
                <Adnavbar/>
            </div>
            <h1>Dashboard</h1>
            <div className='adhome-dasboard'>
                <div className='total-cars'>
                    <h2>Total Number Of Cars {l}</h2>
                    <button className='btn-1'>check</button>
                </div>
                <div className='available-cars'>
                    <h2>Cars in Available State {al}</h2>
                    <button className='btn-1'>check</button>
                </div>
                <div className='reserved-cars'>
                    <h2>Cars in Reserved State {r}</h2>
                    <button className='btn-1'>check</button>
                </div>
                <div className='non-available-cars'>
                     <h2>Cars arrived Out {non}</h2>
                     <button className='btn-1'>check</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Adhome