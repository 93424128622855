import React from 'react'
import '../styles/contact.css'
import Footer from '../components/Footer'
import log from '../img/24-7.png'
import Navbar from '../components/Navbar'


const contact = () => {
  return (
    <div className='contact-body'>
      <Navbar/>
      <h1>Contact Us</h1>
      <div className='contact-form'>
        <form>
          <label>Email</label>
          <input type="email"/>
          <label>Name</label>
          <input type='text'/>
          <label>Comment</label>
          <input type="text"/>
        <button style={{marginTop:"10px"}}className='btn-2'>Submit</button>
        </form>
      </div>
      <div className='contact-details'>
        <img src={log} alt='24/7'/>
          <h2>Address : No-10, opposite to laral industies, adayar, Chennai</h2>
          <h2>Contact : +91 7845462325</h2>
          <h2>Email   : qudacars@gmail.com</h2>
      </div>
      <h3 className='foot' style={{textAlign:"center"}}><Footer /></h3>
    </div>
  )
}

export default contact