import React, { useState } from 'react'
import carData from '../data/cardata';
import '../styles/cardetails.css'
import {Link} from 'react-router-dom'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const Cardetails = () => {
  const [data,setData] = useState(carData);
  const filterresult = (carItem)=>{
    const result = carData.filter((type)=>{
      return type.category=== carItem;
    })
    setData(result)
  }
  return (
    <div className='cardetails-body'>
      <Navbar/>
      <div className='search-bar' >
        <h2>Find Your<br/> Cars Here</h2>
        <div className="vl"></div>
        <div className='button-div' >
          <button className='seachbtn' onClick={() => setData(carData)}>All</button>
          <button className='seachbtn' onClick={() => filterresult("Hatchback")}>Hatchback</button>
          <button className='seachbtn' onClick={() => filterresult("SUV")}>Suv</button>
          <button className='seachbtn' onClick={() => filterresult("Sedan")}>Sedan</button>
          <button className='seachbtn' onClick={() => filterresult("MPV")}>MPV</button>
        </div>
    </div>
      {
      data.map(values =>{
        const {id,brand, imgUrl,description,avil, category, carName, automatic, speed, price } = values;
         
        return(
          <div key={id} className='single-container'>
            <div className='singlecar-boxing'style={{marginTop:"20px"}} >
            <div className='cardiv-right'>
                <img  src={imgUrl} alt='car-img'></img>
              </div>
              
              
              <div className='cardiv-left'>
                <table>
                  <tbody>
                    <tr><td>Brand</td><td>: {brand}</td></tr>
                    <tr><td>Carname</td><td>: {carName}</td></tr>
                    <tr><td>Model</td><td>: {category}</td></tr>
                    <tr><td>Price : Rs-</td><td>: {price}</td></tr>
                    <tr><td>Availability</td><td>: {avil}</td></tr>
                    <tr><td>Mileage</td><td>: {speed}</td></tr>
                    <tr><td>Gear Type</td><td>: {automatic}</td></tr>
                  </tbody>
                </table>
              </div>
          </div>
          <div className='cardiv-mid'>
                <h3>Car details</h3>
                <p>{description}</p>
                <button className='btn-1'><Link to={`/bookingdetails/${carName}`}>Book Now</Link></button>
              </div>  
        </div>
        )
      })
      } 
      <h3 style={{textAlign:"center",color:"white"}}><Footer /></h3>
    </div>
    
  )
}
export default Cardetails;
