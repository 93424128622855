import React from 'react'
import '../styles/Home.css'
import {Link} from 'react-router-dom'
import Footer from '../components/Footer'
import door from '../img/clip-1655.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faFacebook,
    faTwitter,
    faInstagram
  } from "@fortawesome/free-brands-svg-icons";
import jeep from '../img/taxi.png'
import man from '../img/man t.png'
import Navbar from '../components/Navbar'



const Home = () => {
  
  return (
    <div className='home-body'>
        <Navbar/>
        <div className='inner-home'>
            <h1 className='head-h1'>Welcome To Quda Cars</h1>
            <div className='taxi-img'>
                <img src={jeep} alt='jeep'/>
            </div>
            <h1 style={{marginTop:"30px"}}>Make Your Journey With Us</h1>
            <div className='homepage-content'>
              <div className='homepage-left'>
              <img className='logo' src={man} alt="Logo" />
              </div>
              <div className='homepage-right'>
              <h1>Reserve Your Cars Now</h1>
                <h3>Check other Cars here</h3>
                <button className='btn-1'><a href='/cardetails'>Car List</a></button>
                
              </div>
            </div>
            <div className='home-1'>
                <div className='door-stepimg'>
                    <img src={door} alt='doorstep'/>
                </div>
                <div className='home-services'>
                    <h1>Door Step Pick At Any Locations</h1>
                    <button className='btn-1'><Link to='/about'>Service</Link></button>
                </div>
            </div>




            {/*Footer*/}
            <div className='footer-div'>
                <div className='social'>
                <a href="/#"
                        className="youtube social">
                        <FontAwesomeIcon icon={faYoutube} size="2x" />
                    </a>
                    <a href="/#"
                        className="facebook social">
                        <FontAwesomeIcon icon={faFacebook} size="2x" />
                    </a>
                    <a href="/#" className="twitter social">
                        <FontAwesomeIcon icon={faTwitter} size="2x" />
                    </a>
                    <a href="/#"
                        className="instagram social">
                        <FontAwesomeIcon icon={faInstagram} size="2x" />
                    </a>
                </div>
                <div className='add'>
                    <h2>Address : No-10, opposite to laral industies, adayar, Chennai</h2>
                    <h2>Contact : +91 7845462325</h2>
                    <h2>Email   : qudacars@gmail.com</h2>
                </div>
            </div>
        </div>
        <h3 className='foot' style={{textAlign:"center"}}><Footer /></h3>
    </div>
  )
}

export default Home