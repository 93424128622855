import React from 'react'
import '../styles/footer.css'
const Footer = () => {
  return (
    <div className='main-footer'>
        <h2 style={{backgroundColor:"rgb(47, 184, 184)",color:"white",height:"50px",paddingTop:"15px"}}>CopyRights {new Date().getUTCFullYear()}@ Quda Cars</h2>  
    </div>
  )
}

export default Footer