import React from 'react'
import '../styles/about.css'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'


const About = () => {
  return (
    <div className='about-body'>
      <Navbar/>
        <div className='about-para'>
          <h1>About Us</h1>
            <p>Quda Rentals. Car Rentals is a new, independently-owned car rental service in Coimbatore. 
            It will be solely-owned by local entrepreneur Quda Rentals Montgomery. Quda Rentals will provide 
            car rentals to residents of Coimbatore 
            and visitors to the city who need a vehicle for a few days. 
            Quda Rentals. initial fleet of vehicles will include 2 sedans, 2 large SUVs, and 2 luxury vehicles. 
            Through his market research, Quda Rentals found that visitors to the city need a
             sedan to get them around for 
            a few days so that they don’t have to rely on public transportation. 
            There are also residents who sometimes need to rent a truck for a day or two while they 
            move something large. There is also a demand for people to want to rent a luxury vehicle 
            for a few days so they can use it for a special occasion without having to put a large 
            investment to purchase one of their own.
            <br></br>
            Quda Rentals. Fine Car Rentals will be located near the airport in the central part of town.
             For now, he will run the business himself and will handle all client communication and 
             management of his fleet.</p>
        </div>
        <h3 className='foot' style={{textAlign:"center", marginTop:"20px"}}><Footer /></h3>
    </div>
  )
}
export default About