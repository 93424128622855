import React from "react";
import Login from './pages/Login'
import Home from './pages/Home'
import Contact from './pages/contact'
import About from './pages/About'
//import Navbar from "./components/Navbar";
import Cardetails from "./pages/Cardetails";
import Bookingdetails from "./pages/Bookingdetails";
import Register from "./pages/Register";
import {BrowserRouter as Router,Route,Routes} from "react-router-dom";
import './index.css';
import Adminhome from '../src/admin/pages/Adminhome'



const App = () =>{
  return (
	<div className="container">
    	<Router>
    		<Routes>
				<Route  path="/" element={<Login/>}/>
				<Route  path="/home" element={<Home/>}/>
				<Route path="/register" element={<Register/>}/>
				<Route  path="/about" element={<About/>}/>
				<Route  path="/contact" element={<Contact/>}/>
				<Route  path="/cardetails" element={<Cardetails/>}/>
				<Route  path="/bookingdetails/:name" element={<Bookingdetails/>}/>

				{/* Admin panel Route */}
				<Route  path="/adminhome" element={<Adminhome/>}/>

        	</Routes>
		</Router>
	</div>
  );
};
export default App;