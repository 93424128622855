import React,{useState} from 'react'
import '../styles/adnavbar.css'
  

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="Navbar">
      <h1 className="nav-logo">Quda Cars</h1>
      <div className={`nav-items ${isOpen && "open"}`}>
        <a href="/das">Home</a>
        <a href="/asd">Home</a>
        <a href="/Aasddd">Booked cars</a>
        <a href="/abadsout">Add cars</a>
        <a href="/loadsgin">LogOut</a>
      </div>
      <div
        className={`nav-toggle ${isOpen && "open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="bar"></div>
      </div>
    </div>
  );
};

export default Navbar