import swift from '../img/carimg/swift.png'
import kwid from '../img/carimg/renult Kwid c.png'
import mini from '../img/carimg/mini.png'
import ciaz from '../img/carimg/ciaz.png'
import elentra from '../img/carimg/elantra.png'
import bmwx1 from '../img/carimg/BMW X1.png'
import bmw5 from '../img/carimg/bmw5.png'
import ertiga from '../img/carimg/ertiga.png'
import crysta from '../img/carimg/crysta.png'
import fortuner from '../img/carimg/fortuner.png'
import rangerover from '../img/carimg/rangerover.png'
import scorpio from '../img/carimg/scorpio.png'



const carData = [
    {
        id: 1,
        brand: "Maruthi",
        category: "Hatchback",
        carName: "Swift",
        imgUrl: swift,
        avil:"Available",
        price: 1200,
        speed: "20kmpl",
        automatic: "Manual",
        description:
          " About The car",
      },
      {
        id: 2,
        brand: "Renault",
        category: "Hatchback",
        carName: "Kwid",
        imgUrl: kwid,
        avil:"Available",
        price: 1200,
        speed: "20kmpl",
        automatic: "Manual",
        description:
          " About The car",
      },
      {
        id: 3,
        brand: "Mini cooper",
        category: "Hatchback",
        carName: "Mini cooper S3",
        imgUrl: mini,
        avil:"Available",
        price: 1200,
        speed: "20kmpl",
        automatic: "Manual",
        description:
          " About The car",
      },
      {
        id: 4,
        brand: "Maruthi",
        category: "Sedan",
        carName: "Ciaz",
        imgUrl: ciaz,
        avil:"Available",
        price: 1500,
        speed: "17mpl",
        automatic: "Manual",
        description:
          " About The car",
      },
      {
        id: 5,
        brand: "Hyundai",
        category: "Sedan",
        carName: "Elantra",
        imgUrl: elentra,
        avil:"Available",
        price: 1500,
        speed: "17mpl",
        automatic: "Manual",
        description:
          " About The car",
      },
      {
        id: 6,
        brand: "BMW",
        category: "Sedan",
        carName: "BMW X1",
        imgUrl: bmwx1,
        avil:"Available",
        price: 1800,
        speed: "19mpl",
        automatic: "Manual",
        description:
          " About The car",
      },
      {
        id: 7,
        brand: "BMW",
        category: "Sedan",
        carName: "BMW 5 Series",
        imgUrl: bmw5,
        avil:"Available",
        price: 1800,
        speed: "19mpl",
        automatic: "Manual",
        description:
          " About The car",
      },
      {
        id: 8,
        brand: "Maruthi",
        category: "MPV",
        carName: "Ertiga",
        imgUrl: ertiga,
        avil:"Available",
        price: 1500,
        speed: "16mpl",
        automatic: "Manual",
        description:
          " About The car",
      },
      {
        id: 9,
        brand: "Toyota",
        category: "MPV",
        carName: "Innova Crysta",
        imgUrl: crysta,
        avil:"Available",
        price: 1500,
        speed: "16mpl",
        automatic: "Manual",
        description:
          " About The car",
      },
      {
        id: 10,
        brand: "Mahindra",
        category: "SUV",
        carName: "Scorpio",
        imgUrl: scorpio,
        avil:"Available",
        price: 1500,
        speed: "16mpl",
        automatic: "Manual",
        description:
          " About The car",
      },
      {
        id: 11,
        brand: "Toyota",
        category: "SUV",
        carName: "Fortuner",
        imgUrl: fortuner,
        avil:"Available",
        price: 1900,
        speed: "19mpl",
        automatic: "Manual",
        description:
          " About The car",
      },
      {
        id: 12,
        brand: "Land Rover",
        category: "SUV",
        carName: "Evoque",
        imgUrl: rangerover,
        avil:"Available",
        price: 1900,
        speed: "16mpl",
        automatic: "Manual",
        description:
          " About The car",
      },
      
];
export default carData;